
import React from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';

import { StyledModalCloseBtn } from 'components/galleries/modalGallery/styled';

const LoadableModal = loadable(() => import( /* webpackChunkName: "gatsby-Modal" */'react-responsive-modal'));

const ModalWrapper = ({ props, component, hideCloseButton = false}) => {
   return (
     <LoadableModal
       open={props.openModal}
       onClose={props.onCloseModal}
       center={true}
       showCloseIcon={false}
       classNames={{
        overlay: 'maps-modal-overlay',
        modal: 'maps-modal'
    }}
     >
       {!hideCloseButton && <StyledModalCloseBtn onClick={props.onCloseModal} />}
       {component && component}
     </LoadableModal>
   )
};

ModalWrapper.propTypes = {
  component: PropTypes.object,
  hideCloseButton: PropTypes.bool,
  onCloseModal: PropTypes.func,
  openModal: PropTypes.bool,
  props: PropTypes.object
}

export default ModalWrapper;

